.object_wrapper {
    margin-bottom: 30px;
}

.object {
    display: block;
    text-decoration: none;

    &:focus {
        outline: 0;
    }

    .image-wrapper {
        position: relative;

        &:hover {
            .overlay-color {
                background-color: rgba(0,0,0,0.3);
            }
        }

        .overlay-color {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        
        .prijs {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $color1;;
            padding: 10px 25px;
            font-weight: 700;
            color: #fff;
        }
    }

    figure {
        position: relative;

        img {
            display: block;
            width: 100%;
        }

        span {
            display: block;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.4);
            transition: all 0.2s;

            &.prijs {
                bottom: 0;
                right: 0;
            }

            i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #FFF;
                font-size: 36px;
            }

        }
    }

    .text {
        padding-top: 20px;

        h2 {
            font-weight: bold !important;
            font-size: 18px !important;
            margin: 0 0 5px 0 !important;
            color: #000;
        }

        h3 {
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0px;
            color: #999;
            margin-bottom: 10px;
        }

        p {
            color: #000;
            margin-bottom: 15px;
        }

        .prijs {
            display: inline-block;
            padding: 8px 15px;
            font-weight: bold;
            background: $color1;
            color: #fff;
            font-size: 18px;
            transition: all 0.2s;
        }
    }

    &:hover {
        text-decoration: none;

        figure {
            span {
                opacity: 1;
            }
        }

        .text {
            .prijs {
                background: #CFCFCF;
            }
        }
    }

     .icons {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
        color: #000;
        margin-top: 20px;
        
        .icon {
            color: #000;
            font-size: 18px;
            text-align: center;
            padding: 10px 0;
            float: left;
            width: 23.33%;

            &:first-child {
                width: 30%;
            }
            
            &.last-icon,
            &.last-icon:hover{ 
                border-right: none;
            }
            
            .fi {
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }
}