#biedingsformulier {
    .wrapper {
        form {
            span {
                font-size: 16px;
                &.subtitle {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
            label {
                font-weight: normal;
                color: $color1;
                &.radio-label,
                &.checkbox-label {
                    margin-right: 15px;
                    display: inline-block;
                    color: #000;
                }
            }
            .is-invalid {
                border: 1px solid #a94442;
            }
            .help-block {
                color: #a94442;
            }
            .with-margin {
                display: block;
                margin: 30px 0;
            }
        }
    }
}