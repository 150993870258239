#aanbod {

    .sort {
        display: inline;
        line-height: 24px;
        padding-bottom: 20px;

        .sort_wrapper {
            display: inline-block;

            ul {
                display: inline-block;
                padding-left: 20px;
                padding-right: 20px;
                margin: 0px;

                li {
                    display: inline-block;

                    a {
                        color: $color2;
                        text-decoration: none;
                        line-height: 24px;
                    }

                    &.active a {
                        font-weight: 700;
                    }

                    &:not(:last-child):after {
                        content: " / ";
                    }
                }
            }
        }

        span {
            font-size: 24px;
            vertical-align: -5px;
            color: tint($color2, 50%);
            margin-left: 5px;

            &:hover,
                &.active {
                color: $color2;
            }

            &:not(.active){
                cursor: pointer;
            }

            &.icon-map {
                font-size: 28px;
                vertical-align: -7px;
            }
        }
    }
    
    .infobubble {
        img {
            width: 100%;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #aanbod {
        .sort {
            display: inline;
            line-height: 24px;
            padding: 10px 15px;
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #aanbod {
        .sort {
            padding: 20px 15px;
        }
    }
}