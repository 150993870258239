.carousel-wrapper {
    position: relative;
    &.page {
        .slide-wrapper {
            .slide {
                position: relative;
                object-position: bottom;
                background-size: cover;

                .payoff {
                    display: none;
                }

                img {
                    display: block;
                    height: 60vh;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
                }
            }
        }
    }

    .slide-wrapper {
        .slide {
            position: relative;

            .payoff {
                position: absolute;
                top: calc(50% + 35px);
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;
        
                h2, h3, h4 {
                    font-weight: normal;
                    color: #FFF;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
        
                h3 {
                    text-transform: none;
                    margin-bottom: 10px;
                }
        
                h4 {
                    margin-bottom: 0;
                    font-size: 18px;
        
                    a {
                        color: #FFF;
                        text-decoration: none;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
        
                a.btn {
                    margin: 15px 15px 0 15px;
                }
            }

            img {
                width: 100%;
                height: auto !important;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .payoff {
                    h2 {
                        font-size: 30px;
                    }

                    h3, h4 {
                        font-size: 24px;
                    }

                }
                img {
                    width: 100%;
                    height: auto !important;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .carousel-wrapper {
        .slide-wrapper {
            .slide {
                .payoff {
                    h2 {
                        font-size: 40px;
                    }

                    h3 {
                        font-size: 20px;
                    }

                    h4 {
                        font-size: 24px;
                    }
                }

                img {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .carousel-wrapper {

        .slide-wrapper {
            .slide {

                .payoff {
                    h2 {
                        font-size: 48px;
                    }
                }
            }
        }
    }
}
