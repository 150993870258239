#nieuwbouw {
    padding-top: 100px;

    .project_wrapper {
        margin-bottom: 30px;
    }

    .project {
        display: block;
        background: #FFF;
        text-decoration: none;
        color: $color1;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        &:hover {
            text-decoration: none;
        }

        figure {
            position: relative;
            margin: 0px;

            img {
                display: block;
                width: 100%;
            }

            figcaption {
                position: absolute;
                right: 0px;
                bottom: 0px;
                background: $color1;
                color: #FFF;
                padding: 0 15px;
                line-height: 50px;
                font-family: $font1;
                font-weight: 700;
                font-size: 18px;
                border-radius: 2px;
            }
        }

        .text {
            padding: 15px 15px 0 15px;
            height: 110px;

            h2, h3 {
                font-size: 16px !important;
                font-family: $font1;
                margin: 0;
                color: #5b5b5b;
            }

            h2 {
                font-size: 20px !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

#details {
    .bouwtype_wrapper {
        margin-bottom: 30px;
    }

    .bouwtype {
        display: block;
        background: #FFF;
        text-decoration: none;
        color: $color2;
        border: 1px solid #e0e0e0;
        border-radius: 4px;

        &:hover {
            text-decoration: none;
        }

        figure {
            position: relative;
            margin: 0px;

            img {
                display: block;
                width: 100%;
            }

            figcaption {
                position: absolute;
                right: 0px;
                bottom: 0px;
                background: $color1;
                color: #FFF;
                padding: 0 15px;
                line-height: 50px;
                font-family: $font1;
                font-weight: 700;
                font-size: 18px;
                border-radius: 2px;
            }
        }

        .text {
            padding: 15px 15px 0 15px;
            height: 110px;

            h2, h3 {
                font-size: 16px !important;
                font-family: $font1;
                margin: 0;
                color: #5b5b5b;
            }

            h2 {
                font-size: 20px !important;
                margin-bottom: 10px !important;
            }
        }
    }

}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

}