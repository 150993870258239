footer {
    text-align: center;

    address {
        font-style: normal;
        font-size: 18px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {

                &:first-child {
                    font-weight: bold;
                }

                a {
                    color: #000;
                }

                
            }
        }
    }

    .wrapper-black {
        padding: 30px 0;

        .brands {
            padding-top: 30px;
            margin-bottom: 30px;

            .brand {
                margin-bottom: 15px;
            }

            span {
                font-size: 30px;

                &:not(:last-of-type) {
                    margin-right: 30px;
                }
            }
        }

        ul.social {
            list-style: none;
            padding: 0;
            margin: 0 0 10px 0;

            li {
                display: inline-block;
                margin-left: 5px;

                a {
                    color: #FFF;
                    text-decoration: none;

                    span {
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        border-radius: 24px;
                        display: inline-block;
                        font-size: 24px;
                        background: $color1;
                        color: #FFF;
                        text-align: center;
                    }
                }
            }
        }

        a {
            color: #FFF;
            text-decoration: none;
        }

        small {
            font-size: 16px;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    footer {
        .wrapper-black {
            .brands {
                .brand {
                    margin-bottom: 0;

                    span {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    footer {
        address {
            ul {
                li {
                    display: inline-block;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .wrapper-black {
            .brands {
                .brand {
                    span {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}