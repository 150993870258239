* {
    box-sizing: border-box;
}

body {
    font-family: $font1;
    font-size: 18px;
    line-height: 1.7;
    overflow-x: hidden;
    padding-top: 80px;

    .container-fluid {
        margin: 0  auto;
        min-width: 320px;
        max-width: 1440px;
    }
}

.mfp-img-mobile {
    img { 
        .mfp-img {
            max-height: 1000px;
        }
    }
}

h1, h2, h3 {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 15px 0;
    color: $color1;
    line-height: 1.2;
    
}

.slide {
    &:focus {
        outline: 0;
    }
}

h2 {
    font-size: 26px;
}

h3 {
    font-size: 20px;
    margin: 0 0 10px 0;
}

h4 {
    font-size: 20px;
    margin: 0 0 10px 0;
}

a {
    color: #666;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
      }
}

.wrapper ,
.search-wrapper,
.header-wrapper {
    padding: 30px 0;

    &.wrapper-lightgray {
        background: #f8f8f8;
    }

    &.wrapper-gray {
        background: #ededed;
    }


    &.wrapper-black {
        background: #000;
        color: #FFF;
    }
}

p {
    margin: 0 0 30px 0;
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.with-padding-bottom {
    padding-bottom: 30px;
}

blockquote {
    background: #F5F5F5;
    padding: 30px;
    margin: 0 0 30px 0;
}

.box_wrapper {
    position: relative;
    border: 1px solid #4f4f4f;
    padding: 30px;
    min-height: 350px;
    margin: 15px 0 45px 0;

    ul {
        list-style: none;
        padding: 0;
        margin: 30px 0 0 15px;

        li {
            background: url('/img/checked.svg') 0 9px no-repeat;
            background-size: 15px;
            padding: 5px 0 5px 30px;
        }
    }

    p {
        position: absolute;
        bottom: 0;
        left: 30px;
    }
}

.slick-dots {
    position: absolute;
    left: 0px;
    bottom: -40px;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #fff;
        margin: 0px 3px 5px 3px;

        &.slick-active {
            background: #000;
        }

        button {
            display: none;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    body {
        padding-top: 131px;
    }
    .wrapper {
        padding: 45px 0;
    }

    .header-wrapper {
        h1, h2 {
            margin: 0;
        }
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 45px;
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
body {
    padding-top: 170px;
}
    .wrapper {
        padding: 60px 0;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 60px;
    }
}