.btn {
    display: inline-block;
    background: #000;
    color: #FFF;
    padding: 15px 30px;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    border: none;
    transition: all 200ms ease;
    text-transform: uppercase;
    &:hover {
        background: $color1;
    }
    &.btn-blue {
        background: $color1;
        &:hover {
            background: #000;
        }
    }
    &.btn-red {
        background: red;
    }
    &.btn-line {
        background: none;
        border: 1px solid #CCC;
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
    .btn {
        padding: 10px 15px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
    #search {
        .form-group {
            width: 14.28%;
            margin-bottom: 0px;
            .form-control {
                padding: 10px 15px;
                height: 42px;
            }
        }
    }
}