header {
    position: fixed;
    left: 0;
    top: 0;
    height: 80px;
    width: 100%;
    z-index: 2;

    .nav_wrapper {
        width: 100%;
        z-index: 11;
        transition: all 0.2s;
        // background-color: rgba(0,0,0,0.6);
        background: #fff;

        .container-fluid {
            position: relative;

            .logo {
                position: relative;
                z-index: 11;
                display: block;
                height: 70px;
                margin: 5px 0;
            }

            .button_wrapper {
                position: absolute;
                top: 17px;
                right: 15px;
                z-index: 11;

                ul {
                    display: block;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        display: inline-block;

                        a {
                            display: block;
                            width: 36px;
                            height: 36px;
                            border: 1px solid #CCC;
                            line-height: 36px;
                            border-radius: 18px;
                            color: #ccc;
                            text-decoration: none;
                            text-align: center;
                            transition: all 0.2s;

                            span {
                                line-height: 34px;
                            }

                            &:hover {
                                background: none;
                                color: $color1;
                                border-color: $color1;
                            }
                        }

                        &:first-child {
                            vertical-align: 1px;
                            letter-spacing: normal;

                            a {
                                font-size: 14px;
                            }
                        }

                        &:last-child {
                            margin-left: 15px;

                            a {
                                border: 1px solid $color1;
                                background: none;
                                color: $color1;
                                font-size: 15px;
                            }
                        }

                    }
                }
            }
        }

        &.fixed {
            box-shadow: 0 0 10px rgba(#000, 0.1);
        }
    }

    nav {
        position: fixed;
        right: 0px;
        top: 80px;
        width: 100%;
        display: none;
        z-index: 10;
        overflow: hidden;
        background: #000;

        ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            border-bottom: 1px solid #999;

            li {
                border-top: 1px solid #999;

                a {
                    display: block;
                    color: #FFF;
                    text-decoration: none;
                    line-height: 30px;
                    padding: 5px 15px;
                    user-select: none;

                    &:hover,
                    &.active {
                        background: rgba(0, 0, 0, 0.3);
                    }

                    &.active {
                        font-weight: bold;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                        float: right;
                        line-height: 30px !important;
                        transform: rotate(180deg);
                        transition: all 0.2s;

                        &.toggle {
                            transform: rotate(0deg);
                        }
                    }

                }

                > ul {
                    background: #000;
                    display: none;

                    > li {
                        a {
                            white-space: nowrap;
                            padding-left: 25px;

                            // &:hover {
                            //     background: none;
                            // }
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    header {

        .nav_wrapper {
            .logo_wrapper {
                position: absolute;
                right: 150px;
                top: 20px;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline-block;
                        margin-right: 15px;

                        a {
                            color: #ccc;
                            text-decoration: none;
                            transition: all 300ms ease;

                            &:hover {
                                color: $color1;
                            }
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    header {
        height: 131px;
        .nav_wrapper {
            .container-fluid {
                .logo_wrapper {
                    top: 25px;
                    right: 70px;

                    ul {
                        li {
                            margin-right: 30px;

                            a {
                                i {
                                    font-size: 30px;
                                }
                            }
                        }
                    }
                }
                .button_wrapper {
                    transition: all 0.2s;
                }
            }
        }

        nav {
            position: fixed;
            top: 80px;
            display: block !important;
            padding: 0;
            width: 100%;
            overflow: visible;
            background: $color1;
            transition: all 0.2s;

            > ul {
                border-bottom: none;
                white-space: nowrap;
                margin-left: 90px;
                border: none;
                text-align: center;
                padding: 10px 0;

                > li {
                    border-top: none;
                    position: relative;
                    display: inline-block;

                    a {
                        padding: 0px 15px;
                        font-size: 16px;
                        color: #fff;
                        font-weight: bold;

                        span {
                            display: none;
                        }

                        &:after {
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            display: block;
                            content: '';
                            width: 0;
                            height: 1px;
                            background: $color1;
                            transition: all 0.2s;
                        }
                    }

                    &:hover > a,
                    > a.active {
                        position: relative;
                        background: none;

                        &:after {
                            left: 5px;
                            width: calc(100% - 10px);
                        }
                    }

                    > ul {
                        position: absolute;
                        top: 100%;
                        left: 5px;
                        background: $color1;
                        text-align: left;
                        display: block;
                        z-index: 10;
                        overflow: hidden;
                        transition: all 0.3s;
                        transform: translate(0, 15px);
                        opacity: 0;
                        visibility: hidden;
                        border: none;

                        > li {
                            display: block;
                            border-top: none;

                            a {
                                padding: 5px 15px;
                                text-transform: none;
                                font-weight: normal;
                                color: #fff;

                                // &.active {
                                //     background: #F5F5F5;
                                // } 
                            }
                        }
                    }

                    &:hover {
                        ul {
                            transform: none;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

// ************************************ *****************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
    header {
        height: 170px;

        .nav_wrapper {
            .container-fluid { 
                .logo {
                    height: 90px;
                    margin: 5px 0;
                    transition: all 0.2s;
                }
            }

            &.fixed {
                .container-fluid {
                    position: relative;

                    .logo {
                        height: 50px;
                        margin: 10px 0;
                    }

                    nav {
                        top: 22px;
                    }

                    .button_wrapper {
                        top: 16px;
                    }
                }
            }
        }

        nav {
            top: 100px;

            .btn-nav {
                background: #fff;
                color: $color1;
                font-size: 14px;
                padding: 10px 10px;
                font-weight: bold;

                &:hover {
                    color: #000;
                    background: #fff;
                }
            }

            > ul { 
                margin-left: 0px;

                > li {
                    a {
                        font-size: 18px;
                        padding: 0px 20px;
                        // font-weight: 600;
                    }

                    &:hover > a,
                    > a.active {
                        &:after {
                            left: 10px;
                            width: calc(100% - 20px);
                        }
                    }

                    > ul {
                        left: 10px;
                    }
                }
            }
        }

        .button_wrapper {
            top: 20px;
        }
    }
}