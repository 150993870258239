@font-face {
    font-family: 'Neutraface';
    src: url('fonts/Neutraface2Text-Book.eot');
    src: url('fonts/Neutraface2Text-Book.eot?#iefix') format('embedded-opentype'),
    url('fonts/Neutraface2Text-Book.woff2') format('woff2'),
    url('fonts/Neutraface2Text-Book.woff') format('woff'),
    url('fonts/Neutraface2Text-Book.ttf') format('truetype'),
    url('fonts/Neutraface2Text-Book.svg#Neutraface') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neutraface';
    src: url('fonts/Neutraface2Text-Bold.eot');
    src: url('fonts/Neutraface2Text-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Neutraface2Text-Bold.woff2') format('woff2'),
    url('fonts/Neutraface2Text-Bold.woff') format('woff'),
    url('fonts/Neutraface2Text-Bold.ttf') format('truetype'),
    url('fonts/Neutraface2Text-Bold.svg#Neutraface') format('svg');
    font-weight: bold;
    font-style: normal;
}
