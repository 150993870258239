#news {
    .articles {
        article {
            position: relative;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            margin-bottom: 30px;

            a {
                text-decoration: none;

                figure {
                    img {
                        width: 100%;
                    }
                }

                .text {
                    padding: 15px 15px 75px;

                    h2 {
                        margin: 0;
                    }
                    time {
                        display: inline-block;
                        font-size: 14px;
                        margin-bottom: 15px;
                    }
                    p {
                        margin: 0;
                    }
                    button {
                        position: absolute;
                        left: 15px;
                        bottom: 15px;
                        margin-top: 15px;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
}