#homepage {
    .foto_wrapper {
        img {
            width: 100%;
        }
    }

    .slick {
        a.slide {
            outline: none;

            .bg {
                position: relative;
                display: block;
                padding-bottom: 100%;

                .btn {
                    position: absolute;
                    bottom: 20%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    transition: all 0.2s all;
                }
            }

            &.korte-termijn {
                .bg {
                    background: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .5)), url(/img/korte-termijn.jpg) center center no-repeat;
                    background-size: cover;
                }
            }

            &.lange-termijn {
                .bg {
                    background: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .5)), url(/img/lange-termijn.jpg) center center no-repeat;
                    background-size: cover;
                }
            }

            &.interieur-styling {
                .bg {
                    background: linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .5)), url(/img/interieur-styling.jpg) center center no-repeat;
                    background-size: cover;
                }
            }

            &:hover {
                .bg {
                    .btn {
                        background: #FFF;
                        border: 1px solid #FFF;
                        color: #000;
                    }
                }
            }
        }

        .slick-dots {
            width: 100%;
            text-align: center;
            list-style: none;
            padding: 0px;
            margin: 15px 0 0 0;

            li {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #CCC;
                margin: 0px 3px 5px 3px;

                &.slick-active {
                    background: #666;
                }

                button {
                    display: none;
                }
            }
        }
    }

    .wrapper-lightgray {
        h2 {
            
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 40px;
        }
    }
}

#usps_wrapper {
    text-align: center;
    h2{
        color: #000;
    }
    #usps {
        a {
            display: block;
            color: #fff;
            text-decoration: none;
        }

        .slide {
            h2 {
                text-transform: none;
            }
        }

        .hexagon {
            display: inline-block;
            position: relative;
            width: 200px; 
            height: 115.47px;
            background-color: shade($color1, 1%);
            margin: 57.74px 0;

            span {
                position: absolute;
                left: 50%;
                top: 50%;
                font-size: 100px;
                transform: translate(-50%, -50%);

                &.icon-huisverkopen,
                &.icon-aankoopmakelaar {
                    margin-left: 10px;
                }

                &.icon-taxaties {
                    margin-left: 5px;
                }
            }

            &:before,
                &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 0;
                border-left: 100px solid transparent;
                border-right: 100px solid transparent;
            }

            &:before {
                bottom: 100%;
                border-bottom: 57.74px solid shade($color1, 1%);
            }

            &:after {
                top: 100%;
                border-top: 57.74px solid shade($color1, 1%);
            }
        }

        h2 {
            color: #000;
            font-size: 22px;
        }

        p {
            margin-bottom: 0px;
        }

        .slick-dots {
            width: 100%;
            text-align: center;
            list-style: none;
            padding: 0px;
            margin: 15px 0 0 0;

            li {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: rgba(#FFF, 0.5);
                margin: 0px 3px 5px 3px;

                &.slick-active {
                    background: #FFF;
                }

                button {
                    display: none;
                }
            }
        }
    }

    .btn_wrapper {
        margin-top: 15px;
    }
}