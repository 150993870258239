.employee {
	margin-top: 50px;
}

#overons {
	.timeline {
		margin: 40px 0;
		.line {
			margin-bottom: 30px;
			span {
				position: absolute;
				width: 80%;
				margin: auto;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			.year {
				text-align: center;
				height: 180px;
				span {
					max-height: 46px;
					background: $color1;
					color: #FFF;
					border-radius: 5px;
					display: block;
					padding: 10px 20px;
					font-size: 18px;
					&:after {
						position: absolute;
						content: '';
						height: 210px;
						width: 1px;
						background: $color1;
						left: 50%;
						top: -170%;
						z-index: -1;
					}
				}
			}
			.image {
				span {
					border-radius: 50%;
					border: 2px solid $color2;
					width: 100px;
					height: 100px;
					overflow: hidden;
					background: #FFF;

					.img-1977 {
						margin-top: -70px;
					}
					.img-1982 {
						margin-left: -40px;
					}
					.img-1984 {
						margin-top: -25px;
    					margin-left: -40px;
					}
					.img-1995 {
						margin-top: -120px;
						margin-left: -70px;
					}
					.img-1998 {
						margin-left: -65px;
					}
					.img-1999 {
						margin-left: -40px;
						margin-top: -5px;
					}
					.img-2002 {
						margin-left: -15px;
						margin-top: 5px;
					}
					.img-2004 {
						margin-left: -85px;
					}
					.img-2005 {
						margin-left: -45px;
					}
					.img-2017 {
						margin-left: -15px;
					}
				}
			}
			.text {
				display: flex;
				text-align: center;
				span {
					height: auto;
					padding: 20px;
					background: #F5F5F5;
					border-radius: 5px;
					border: 1px solid $color1;
					p {
						margin-bottom: 0;
					}
				}
			}

			&:nth-child(odd) {
				.image {
					span {
						top: -20%;
					}
				}
			}
			&:nth-child(even) {
				.image {
					span {
						top: 20%;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	#overons {
		.timeline {
			.line {
				&:first-child {
					.year {
						&:before {
							content: '';
							position: absolute;
							height: 20px;
							width: 20px;
							border-radius: 50%;
							background: $color1;
							top: -120%;
							left: 1%;
							right: 0;
							bottom: 0;
							margin: auto;
						}
					}				
				}
				&:nth-child(odd) {
					.text {
						text-align: right;
						&:after {
							content: '';
							width: 70%;
							height: 1px;
							background: $color1;
							position: absolute;
							left: 80%;
							top: 50%;
							z-index: -1;
						}
					}
					.image {
						span {
							right: 64%;
							top: 0;
						}
					}
				}
				&:nth-child(even) {
					.text {
						&:before {
							content: '';
							width: 70%;
							height: 1px;
							background: $color1;
							position: absolute;
							right: 80%;
							top: 50%;
							z-index: -1;
						}
					}
					.image {
						span {
							right: -64%;
							top: 0;
						}
					}
				}
				&:last-child {
					.year {
						&:before {
							content: '';
							position: absolute;
							height: 20px;
							width: 20px;
							border-radius: 50%;
							background: $color1;
							top: 120%;
							left: 1%;
							right: 0;
							bottom: 0;
							margin: auto;
						}
					}
				}
			}
		}
	}
}