#breadcrumbsWrapper {
    background: #F5F5F5;
    padding: 10px 0;
    line-height: 1;

    #breadcrumbs {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1;

        li {
            display: inline-block;
            font-size: 14px;
            color: #999;
            line-height: 1;

            &:not(:last-child) {
                &:after {
                    content: "-";
                }
            }

            a {
                font-size: 14px;
                color: #666;
                line-height: 1;
            }
        }
    }
}
