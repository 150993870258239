img.mfp-img {
    max-height: 90vh !important;
}

#details {
    padding-bottom: 0;

    h3.open-huis {
        color: $color2;
        margin: 20px 0 0 0;
    }
    #details-slider,
    #details-thumbs {
        margin-top: 15px;

        .slide {
            height: auto !important;

            img {
                display: block;
                width: 100%;
            }
        }
    }

    #details-thumbs {
        .slide {
            margin-bottom: 20px;
        }
    }

    ul.detail_buttons {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        margin-bottom: 15px;

        li {
            display: inline-block;
            margin: 15px 3px 0 3px;

            a {
                display: block;
                background: #FFF;
                border: 1px solid #CCC;
                color: #000;
                padding: 8px 15px;
                text-decoration: none;
                white-space: nowrap;
                text-align: center;
            }

            &.active a {
                background: #DDD;
                border-color: #DDD;
            }

            &:first-child {
                margin-left: 0;

                a {
                    span {
                        padding: 3px 0 6px;
                        display: block;
                        vertical-align: -3px;
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    .detail_content {
        h2 {
            text-align: center;
            margin-bottom: 30px;
        }

        h3 {
            font-weight: bold;
            color: #000;
            font-size: 16px;
            margin: 0;
            line-height: 40px;
        }

        .foto {
            a {
                position: relative;
                display: block;

                img {
                    display: block;
                    width: 100%;
                    margin: 15px 0;
                }

                &:hover {
                    &:after {
                        font-family: 'icomoon' !important;
                        content: "\e90b";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin: -50px 0 0 -50px;
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        font-size: 38px;
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }

        dl {
            width: 100%;
            margin: 0 0 40px 0;
            border-top: 1px solid #CCC;

            dd, dt {
                display: inline-block;
                border-bottom: 1px solid #CCC;
                width: 50%;
                line-height: 40px;
                margin: 0px;
            }
        }

        #gmap_streetview,
        #gmap_locatie {
            width: 100%;
            height: 400px;
        }

        a.meer {
            color: $color2;
            font-weight: bold;
            text-decoration: none;
        }

        .description_short + .description_long {
            display: none;
        }

        textarea {
            height: 96px;
        }

        .object-overig {
            position: relative;
            width: 100%;
            padding-bottom: 56%;
            margin-bottom: 30px;

            div,
            video,
            iframe {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.totop {
    display: block;
    border: 1px solid #CCC;
    position: fixed;
    right: 15px;
    bottom: 15px;
    padding: 10px 15px 5px 15px;
    text-decoration: none;
    background: #FFF;
    color: #000;

    &:hover {
        text-decoration: none;
        background: #EEE;
    }

    > span {
        display: block;
        transform: rotate(180deg);
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #details {
        .wrapper {
            padding: 20px 0;
        }
    }
}