@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?an46if');
    src: url('fonts/icomoon.eot?an46if#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?an46if') format('truetype'),
    url('fonts/icomoon.woff?an46if') format('woff'),
    url('fonts/icomoon.svg?an46if#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-huis-verkopen:before {
    content: "\e90c";
}

.icon-aankoopmakelaar:before {
    content: "\e90d";
}

.icon-taxaties:before {
    content: "\e910";
}

.icon-pararius_logo:before {
    content: "\e911";
}

.icon-arrow-down:before {
    content: "\e900";
}

.icon-facebook:before {
    content: "\e901";
}

.icon-instagram:before {
    content: "\e902";
}

.icon-mail:before {
    content: "\e903";
}

.icon-menu:before {
    content: "\e905";
}

.icon-phone:before {
    content: "\e906";
}

.icon-kamers:before {
    content: "\e907";
}

.icon-oppervlakte:before {
    content: "\e908";
}

.icon-slaapkamers:before {
    content: "\e909";
}

.icon-verdiepingen:before {
    content: "\e90a";
}

.icon-zoeken:before {
    content: "\e90b";
}

.icon-nwwi:before {
    content: "\e90e";
}

.icon-user:before {
    content: "\e90f";
}

.icon-twitter:before {
    content: "\e928";
}

.icon-youtube:before {
    content: "\e929";
}

.icon-funda:before {
    content: "\e939";
}

.icon-scvm:before {
    content: "\e935";
}

.icon-vbomakelaar:before {
    content: "\e912";
}
